// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alan-js": () => import("./../../../src/pages/alan.js" /* webpackChunkName: "component---src-pages-alan-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dummy-js": () => import("./../../../src/pages/dummy.js" /* webpackChunkName: "component---src-pages-dummy-js" */),
  "component---src-pages-ian-js": () => import("./../../../src/pages/ian.js" /* webpackChunkName: "component---src-pages-ian-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-miles-js": () => import("./../../../src/pages/miles.js" /* webpackChunkName: "component---src-pages-miles-js" */),
  "component---src-pages-tony-js": () => import("./../../../src/pages/tony.js" /* webpackChunkName: "component---src-pages-tony-js" */),
  "component---src-pages-tonythetiger-js": () => import("./../../../src/pages/tonythetiger.js" /* webpackChunkName: "component---src-pages-tonythetiger-js" */)
}


import React from "react";
import { Button } from "../Core";

const ButtonIcon = ({
  variant = "success",
  color = "#fff",
  children,
  ...rest
}) => {
  return (
    <Button variant={variant} color={color} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonIcon;
